/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import reports from './reports'
import accounts from './accounts'
import billing from './billing'
import logs from './logs'
import settings from './settings'

export default [
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
    action: 'read',
    resource: 'public',
  },
  {
    title: 'Change Password',
    route: 'changepassword',
    icon: 'LockIcon',
    action: 'read',
    resource: 'public',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'DatabaseIcon',
    action: 'read',
    resource: 'Dashboard',
  },
  ...accounts.map(list => ({ resource: 'resource' in list ? list.resource : 'loggedIn', action: list.action || 'read', ...list })),
  ...reports.map(list => ({ resource: 'resource' in list ? list.resource : 'loggedIn', action: list.action || 'read', ...list })),
  ...logs.map(list => ({ resource: 'resource' in list ? list.resource : 'loggedIn', action: list.action || 'read', ...list })),
  ...billing.map(list => ({ resource: 'resource' in list ? list.resource : 'loggedIn', action: list.action || 'read', ...list })),
  ...settings.map(list => ({ resource: 'resource' in list ? list.resource : 'loggedIn', action: list.action || 'read', ...list })),
]
