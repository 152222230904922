import { getUserData, isUserLoggedIn } from "@/auth/utils"

export default [
  {
    header: 'ตั้งค่า',
  },
  {
    title: 'Auto Partner',
    route: 'auto-partner-setting',
    icon: 'UserIcon',
    action: 'read',
    resource: 'AutoPartner',
  },

  // {
  //   title: 'Partners Keys',
  //   route: 'partners-keys-setting',
  //   icon: 'UserIcon',
  //   action: 'read',
  //   resource: 'PartnersKeys',
  //   requireUserTypes: ['COMPANY'],
  // },

]
